import { Link } from 'hds-react';
import ExternalLink from "../../../../src/components/ExternalLink";
import Image from "../../../../src/components/Image";
import TabsLayout from "../../../../src/docs/foundation/visual-assets/icons/tabs.mdx";
import InternalLink from "../../../../src/components/InternalLink";
import * as React from 'react';
export default {
  Link,
  ExternalLink,
  Image,
  TabsLayout,
  InternalLink,
  React
};