import { Dialog, Button, IconInfoCircle, IconQuestionCircle, Link, TextArea, TextInput } from 'hds-react';
import ExternalLink from "../../../../src/components/ExternalLink";
import Playground from "../../../../src/components/Playground";
import TabsLayout from "../../../../src/docs/components/dialog/tabs.mdx";
import * as React from 'react';
export default {
  Dialog,
  Button,
  IconInfoCircle,
  IconQuestionCircle,
  Link,
  TextArea,
  TextInput,
  ExternalLink,
  Playground,
  TabsLayout,
  React
};