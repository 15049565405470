import { Link, TimeInput, Select } from 'hds-react';
import ExternalLink from "../../../../src/components/ExternalLink";
import Playground from "../../../../src/components/Playground";
import TabsLayout from "../../../../src/docs/components/time-input/tabs.mdx";
import InternalLink from "../../../../src/components/InternalLink";
import * as React from 'react';
export default {
  Link,
  TimeInput,
  Select,
  ExternalLink,
  Playground,
  TabsLayout,
  InternalLink,
  React
};