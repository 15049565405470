import { Footer, IconFacebook, IconTwitter, IconInstagram, IconYoutube, IconTiktok } from 'hds-react';
import PlaygroundPreview from "../../../../src/components/Playground";
import TabsLayout from "../../../../src/docs/components/footer/tabs.mdx";
import InternalLink from "../../../../src/components/InternalLink";
import * as React from 'react';
export default {
  Footer,
  IconFacebook,
  IconTwitter,
  IconInstagram,
  IconYoutube,
  IconTiktok,
  PlaygroundPreview,
  TabsLayout,
  InternalLink,
  React
};