import { Link, Tag } from 'hds-react';
import ExternalLink from "../../../../src/components/ExternalLink";
import Playground from "../../../../src/components/Playground";
import TabsLayout from "../../../../src/docs/components/tag/tabs.mdx";
import * as React from 'react';
export default {
  Link,
  Tag,
  ExternalLink,
  Playground,
  TabsLayout,
  React
};