import { useState } from 'react';
import { withPrefix } from 'gatsby';
import Playground from "../../../../src/components/Playground";
import ExternalLink from "../../../../src/components/ExternalLink";
import TabsLayout from "../../../../src/docs/components/pagination/tabs.mdx";
import * as React from 'react';
export default {
  useState,
  withPrefix,
  Playground,
  ExternalLink,
  TabsLayout,
  React
};