import { Button, Link, Notification } from 'hds-react';
import ExternalLink from "../../../../src/components/ExternalLink";
import TabsLayout from "../../../../src/docs/components/notification/tabs.mdx";
import * as React from 'react';
export default {
  Button,
  Link,
  Notification,
  ExternalLink,
  TabsLayout,
  React
};