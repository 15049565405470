import { Logo } from 'hds-react';
import PlaygroundPreview from "../../../../src/components/Playground";
import TabsLayout from "../../../../src/docs/components/logo/tabs.mdx";
import InternalLink from "../../../../src/components/InternalLink";
import * as React from 'react';
export default {
  Logo,
  PlaygroundPreview,
  TabsLayout,
  InternalLink,
  React
};