import { useReducer } from 'react';
import { Button, Stepper, StepState, IconArrowLeft, Link } from 'hds-react';
import ExternalLink from "../../../../src/components/ExternalLink";
import Playground from "../../../../src/components/Playground";
import TabsLayout from "../../../../src/docs/components/stepper/tabs.mdx";
import * as React from 'react';
export default {
  useReducer,
  Button,
  Stepper,
  StepState,
  IconArrowLeft,
  Link,
  ExternalLink,
  Playground,
  TabsLayout,
  React
};