import { useState } from 'react';
import PlaygroundPreview from "../../../../src/components/Playground";
import TabsLayout from "../../../../src/docs/components/pagination/tabs.mdx";
import { Pagination } from 'hds-react';
import * as React from 'react';
export default {
  useState,
  PlaygroundPreview,
  TabsLayout,
  Pagination,
  React
};