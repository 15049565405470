import { Notification } from "hds-react";
import ExternalLink from "../../../../src/components/ExternalLink";
import Playground from "../../../../src/components/Playground";
import TextExample from "../../../../src/components/examples/TextExample";
import TabsLayout from "../../../../src/docs/foundation/design-tokens/typography/tabs.mdx";
import * as React from 'react';
export default {
  Notification,
  ExternalLink,
  Playground,
  TextExample,
  TabsLayout,
  React
};