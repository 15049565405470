import { useReducer } from 'react';
import { Checkbox, SelectionGroup, Fieldset } from 'hds-react';
import PlaygroundPreview from "../../../../src/components/Playground";
import TabsLayout from "../../../../src/docs/components/checkbox/tabs.mdx";
import InternalLink from "../../../../src/components/InternalLink";
import * as React from 'react';
export default {
  useReducer,
  Checkbox,
  SelectionGroup,
  Fieldset,
  PlaygroundPreview,
  TabsLayout,
  InternalLink,
  React
};