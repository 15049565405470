import React from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';
import theme from 'prism-react-renderer/themes/github';

import './SyntaxHighlighter.scss';

const SyntaxHighlighter = (props) => {
  const className = props.children.props.className;
  const matches = className.match(/language-(?<lang>.*)/);
  return (
    <Highlight
      {...defaultProps}
      code={props.children.props.children.trim()}
      theme={theme}
      language={matches && matches.groups && matches.groups.lang ? matches.groups.lang : ''}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre className={`${className} syntax-highlighter`} style={style}>
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  );
};

export default SyntaxHighlighter;
