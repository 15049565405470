import { IconDocument, IconEnvelope, IconPhone, IconPhoto, Link } from 'hds-react';
import ExternalLink from "../../../../src/components/ExternalLink";
import Playground from "../../../../src/components/Playground";
import TabsLayout from "../../../../src/docs/components/link/tabs.mdx";
import * as React from 'react';
export default {
  IconDocument,
  IconEnvelope,
  IconPhone,
  IconPhoto,
  Link,
  ExternalLink,
  Playground,
  TabsLayout,
  React
};