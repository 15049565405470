import { Notification, Button } from 'hds-react';
import PlaygroundPreview from "../../../../src/components/Playground";
import TabsLayout from "../../../../src/docs/components/notification/tabs.mdx";
import InternalLink from "../../../../src/components/InternalLink";
import * as React from 'react';
export default {
  Notification,
  Button,
  PlaygroundPreview,
  TabsLayout,
  InternalLink,
  React
};