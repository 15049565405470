import { SideNavigation, IconHome, IconMap, IconCogwheel, Link } from 'hds-react';
import ExternalLink from "../../../../src/components/ExternalLink";
import Playground from "../../../../src/components/Playground";
import TabsLayout from "../../../../src/docs/components/side-navigation/tabs.mdx";
import * as React from 'react';
export default {
  SideNavigation,
  IconHome,
  IconMap,
  IconCogwheel,
  Link,
  ExternalLink,
  Playground,
  TabsLayout,
  React
};