import { Link } from 'hds-react';
import TabsLayout from "../../../../src/docs/components/cookie-consent/tabs.mdx";
import InternalLink from "../../../../src/components/InternalLink";
import ExternalLink from "../../../../src/components/ExternalLink";
import * as React from 'react';
export default {
  Link,
  TabsLayout,
  InternalLink,
  ExternalLink,
  React
};