import { Link, SelectionGroup, Checkbox, RadioButton } from 'hds-react';
import ExternalLink from "../../../../src/components/ExternalLink";
import Playground from "../../../../src/components/Playground";
import TabsLayout from "../../../../src/docs/components/selection-group/tabs.mdx";
import * as React from 'react';
export default {
  Link,
  SelectionGroup,
  Checkbox,
  RadioButton,
  ExternalLink,
  Playground,
  TabsLayout,
  React
};