import { Accordion, Link } from 'hds-react';
import ExternalLink from "../../../../src/components/ExternalLink";
import InternalLink from "../../../../src/components/InternalLink";
import TabsLayout from "../../../../src/docs/getting-started/faq/tabs.mdx";
import * as React from 'react';
export default {
  Accordion,
  Link,
  ExternalLink,
  InternalLink,
  TabsLayout,
  React
};