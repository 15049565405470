import { FileInput } from 'hds-react';
import PlaygroundPreview from "../../../../src/components/Playground";
import TabsLayout from "../../../../src/docs/components/file-input/tabs.mdx";
import InternalLink from "../../../../src/components/InternalLink";
import * as React from 'react';
export default {
  FileInput,
  PlaygroundPreview,
  TabsLayout,
  InternalLink,
  React
};