import { Link } from "hds-react";
import ExternalLink from "../../../../src/components/ExternalLink";
import LeadParagraph from "../../../../src/components/LeadParagraph";
import Image from "../../../../src/components/LeadParagraph";
import * as React from 'react';
export default {
  Link,
  ExternalLink,
  LeadParagraph,
  Image,
  React
};