import ColorExample from "../../../../src/components/examples/ColorExample";
import ExternalLink from "../../../../src/components/ExternalLink";
import InternalLink from "../../../../src/components/InternalLink";
import TabsLayout from "../../../../src/docs/foundation/design-tokens/colour/tabs.mdx";
import * as React from 'react';
export default {
  ColorExample,
  ExternalLink,
  InternalLink,
  TabsLayout,
  React
};