import { Link, Linkbox } from 'hds-react';
import { withPrefix } from "gatsby";
import ExternalLink from "../../../../src/components/ExternalLink";
import TabsLayout from "../../../../src/docs/components/linkbox/tabs.mdx";
import * as React from 'react';
export default {
  Link,
  Linkbox,
  withPrefix,
  ExternalLink,
  TabsLayout,
  React
};