import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { StatusLabel } from 'hds-react';
import LeadParagraph from '../../../components/LeadParagraph';
import Layout from '../../../components/layout';
import PageTabs from '../../../components/PageTabs';
export const _frontmatter = {
  "slug": "/components/text-input/tabs",
  "title": "TextInput"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  pageContext,
  children
}) => <Layout pageContext={pageContext}>{children}</Layout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "textinput",
      "style": {
        "position": "relative"
      }
    }}>{`TextInput`}<a parentName="h1" {...{
        "href": "#textinput",
        "aria-label": "textinput permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <StatusLabel type="info" mdxType="StatusLabel">Stable</StatusLabel>
    <StatusLabel type="success" style={{
      marginLeft: 'var(--spacing-xs)'
    }} mdxType="StatusLabel">
  Accessible
    </StatusLabel>
    <LeadParagraph mdxType="LeadParagraph">
  A text field is an input field that the user can interact with and input content and data. The user can also be provided with helper text if needed. Text inputs are used for shorter information while Text areas can be used for multiline answers.
    </LeadParagraph>
    <PageTabs pageContext={props.pageContext} mdxType="PageTabs">
  <PageTabs.TabList>
    <PageTabs.Tab href="/">Usage</PageTabs.Tab>
    <PageTabs.Tab href="/code">Code</PageTabs.Tab>
    <PageTabs.Tab href="/accessibility">Accessibility</PageTabs.Tab>
  </PageTabs.TabList>
  <PageTabs.TabPanel>{props.children}</PageTabs.TabPanel>
    </PageTabs>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      