import * as React from 'react';

import LinkboxList from './LinkboxList';

const FrontPageProductsList = () => {
  const componentData = [
    {
      name: 'Seree Ecommerce',
      text: 'ขับเคลื่อนธุรกิจอีคอมเมิร์ซของคุณด้วย ระบบเสรี ปลดล็อคการขายผ่านทุกช่องทางการตลาดยุคใหม่ รองรับทุกโมเดลสำหรับธุรกิจทุกขนาด B2B,B2C,D2C ทดลองใช้งานจริงก่อนตัดสินใจ ',
      linkboxAriaLabel: 'Brand guidelines',
      linkAriaLabel: 'Go to the Seree page',
      href: '/services/seree',
      imgProps: {
        src: '/images/services/home-products-seree.svg',
        height: 198,
        width: 300,
      },
    },
    {
      name: 'ERPNext Implementation',
      text: 'ให้คำปรึกษาในการนำระบบอีอาร์พี เข้าไปใช้แก้ปัญหาและพัฒนาต่อยอดธุรกิจของคุณโดยทีมงานที่มีประสบการณ์ กว่า 10 ปี ปรึกษาได้ฟรี ดูรายละเอียดได้จาก link',
      linkboxAriaLabel: 'ERPNext',
      linkAriaLabel: 'Go to the ERPNext page',
      href: '/services/erpnext',
      imgProps: {
        src: '/images/services/home-products-erp.svg',
        height: 198,
        width: 300,
      },
    },
    {
      name: 'ออกแบบและพัฒนาเว็ปไซต์',
      text: 'ไม่ใช่เพียงแค่มีเว็ปไซต์ แต่เราพัฒนาครบทุกความต้องการ ตั้งแต่วางแผนการตลาด ออกแบบที่ตรงกับสินค้าหรือธุรกิจของคุณ เชื่อมต่อกับระบบอื่นๆเพื่อขยายการทำงานร่วมกัน เหมาะสำหรับทุกองค์กรที่กำลังมองหาเว็ปไซต์ที่ช่วยเสริมภาพลักษณ์และขยายช่องทางธุรกิจขึ้นสู่ออนไลน์อย่างสมบูรณ์แบบ',
      linkboxAriaLabel: 'Website',
      linkAriaLabel: 'Go to the website page',
      href: '/services/website',
      imgProps: {
        src: '/images/services/home-products-web.svg',
        height: 198,
        width: 300,
      },
    },
    {
      name: 'พัฒนาแอปพลิเคชั่นบนมือถือ',
      text: 'กว่า 80 เปอเซ็นต์ของลูกค้ายุคปัจจุบันล้วนเข้าใช้งานผ่านมือถือ ดังนั้นการพัฒนาแอปพลิเคชั่นจึงเป็นสิ่งจำเป็นสำหรับทุกองค์กรค์ ทีมพัฒนาของเรามีประสบการณ์พัฒนาแอปพลิเคชั่นมาหลากหลายจึงสามารถตอบโจทย์ของคุณได้แน่นอน ซึ่งสามารถขอคำปรึกษาได้ฟรี ดูรายละเอียดได้ที่ link',
      linkboxAriaLabel: 'Application',
      linkAriaLabel: 'Go to the Application page',
      href: '/services/mobile',
      imgProps: {
        src: '/images/services/home-products-mobile.svg',
        height: 198,
        width: 300,
      },
    },
  ];

  return <LinkboxList data={componentData} className="front-page-link-list" />;
};

export default FrontPageProductsList;
