import { SideNavigation, IconHome, IconMap, IconCogwheel } from 'hds-react';
import PlaygroundPreview from "../../../../src/components/Playground";
import TabsLayout from "../../../../src/docs/components/side-navigation/tabs.mdx";
import InternalLink from "../../../../src/components/InternalLink";
import * as React from 'react';
export default {
  SideNavigation,
  IconHome,
  IconMap,
  IconCogwheel,
  PlaygroundPreview,
  TabsLayout,
  InternalLink,
  React
};