module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/node_modules/gatsby-remark-autolink-headers","id":"365001c1-81e6-52fa-9078-3ddf76c0631d","name":"gatsby-remark-autolink-headers","version":"5.24.0","modulePath":"/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/node_modules/gatsby-remark-autolink-headers/index.js","pluginOptions":{"plugins":[],"icon":"<span class=\"hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs\" aria-hidden=\"true\" style=\"vertical-align: middle\"></span>","isIconAfterHeader":true,"className":"header-anchor"},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onInitialClientRender","shouldUpdateScroll"],"ssrAPIs":["onRenderBody"]}],"defaultLayouts":{"default":"/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js"},"extensions":[".mdx"],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":"<span class=\"hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs\" aria-hidden=\"true\" style=\"vertical-align: middle\"></span>","isIconAfterHeader":true,"className":"header-anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","theme_color":"#ffffff","background_color":"#ffffff","display":"minimal-ui","icons":[{"src":"/favicon/transparent_light_favicon_package/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicon/transparent_light_favicon_package/android-chrome-256x256.png","sizes":"256x256","type":"image/png"}],"include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"831","matomoUrl":"https://webanalytics.digiaiiris.com/js","siteUrl":"https://anndream.com","matomoJsScript":"piwik.min.js","matomoPhpScript":"tracker.php","requireCookieConsent":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
