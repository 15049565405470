import ExternalLink from "../../../../src/components/ExternalLink";
import LeadParagraph from "../../../../src/components/LeadParagraph";
import InternalLink from "../../../../src/components/InternalLink";
import FrontPageProductsList from "../../../../src/components/FrontPageProductsList";
import * as React from 'react';
export default {
  ExternalLink,
  LeadParagraph,
  InternalLink,
  FrontPageProductsList,
  React
};