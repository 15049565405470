import * as React from 'react';

import LinkboxList from './LinkboxList';

const ServicesList = () => {
  const componentData = [
    {
      name: 'ERPNext Implementation',
      text: 'ให้คำปรึกษาและพัฒนาสำหรับนำไปใช้กับบริษัทหรือหน่วยงาน',
      linkboxAriaLabel: 'ERPNext Implementation',
      linkAriaLabel: 'Go to the ERPNext page',
      href: '/services/erpnext',
      imgProps: { 
        src: '/images/services/home-products-erp.svg', 
        alt: 'ERPNext is the world best free and open source ERP',
        height: 180,
        width: 280,
      },
    },
    {
      name: 'ออกแบบและพัฒนาเว็ปไซต์',
      text: 'ออกแบบตามความต้องการของลูกค้า ซึ่งหากต้องการระบบ',
      linkboxAriaLabel: 'ออกแบบและพัฒนาเว็ปไซต์',
      linkAriaLabel: 'Go to the Website Design page',
      href: '/services/website',
      imgProps: { 
        src: '/images/services/home-products-web.svg', 
        alt: 'An illustration of the Button component.',
        height: 180,
        width: 280,
      },
    },
    {
      name: 'พัฒนาแอปพลิเคชั่นบนมือถือ',
      text: 'พัฒนาแอปพลิเคชั่นบนมือถือทุกระบบ ทั้ง Native และ Flutter',
      linkboxAriaLabel: 'พัฒนาแอปพลิเคชั่นบนมือถือ',
      linkAriaLabel: 'Go to the Mobile Application page',
      href: '/services/mobile',
      imgProps: { 
        src: '/images/services/home-products-mobile.svg', 
        alt: 'พัฒนาแอปพลิเคชั่นบนมือถือ',
        height: 180,
        width: 280,
      },
    },
   

  ];

  return <LinkboxList data={componentData} />;
};

export default ServicesList;
