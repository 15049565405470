import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LeadParagraph from '../../../components/LeadParagraph';
import Layout from '../../../components/layout';
import PageTabs from '../../../components/PageTabs';
export const _frontmatter = {
  "slug": "/getting-started/faq/tabs",
  "title": "Frequently asked questions"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  pageContext,
  children
}) => <Layout pageContext={pageContext}>{children}</Layout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "frequently-asked-questions",
      "style": {
        "position": "relative"
      }
    }}>{`Frequently asked questions`}<a parentName="h1" {...{
        "href": "#frequently-asked-questions",
        "aria-label": "frequently asked questions permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
  Here you will find frequently asked questions about HDS and answers to those questions. If you do not find an answer to your question on this page, you can always contact the HDS team for more information.
    </LeadParagraph>
    <PageTabs pageContext={props.pageContext} mdxType="PageTabs">
  <PageTabs.TabList>
    <PageTabs.Tab href="/">General</PageTabs.Tab>
    <PageTabs.Tab href="/design">Design</PageTabs.Tab>
    <PageTabs.Tab href="/code">Code</PageTabs.Tab>
    <PageTabs.Tab href="/accessibility">Accessibility</PageTabs.Tab>
  </PageTabs.TabList>
  <PageTabs.TabPanel>{props.children}</PageTabs.TabPanel>
    </PageTabs>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      