import { Button, Card, IconAngleRight, IconShare, IconTrash } from 'hds-react';
import ExternalLink from "../../../../src/components/ExternalLink";
import PlaygroundPreview from "../../../../src/components/Playground";
import TabsLayout from "../../../../src/docs/components/card/tabs.mdx";
import InternalLink from "../../../../src/components/InternalLink";
import * as React from 'react';
export default {
  Button,
  Card,
  IconAngleRight,
  IconShare,
  IconTrash,
  ExternalLink,
  PlaygroundPreview,
  TabsLayout,
  InternalLink,
  React
};