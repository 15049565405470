import { StatusLabel } from 'hds-react';
import LeadParagraph from "../../../../src/components/LeadParagraph";
import Layout from "../../../../src/components/layout";
import PageTabs from "../../../../src/components/PageTabs";
import * as React from 'react';
export default {
  StatusLabel,
  LeadParagraph,
  Layout,
  PageTabs,
  React
};