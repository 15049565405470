import { Button, IconTrash, IconAngleRight, Notification, IconShare, Link } from 'hds-react';
import ExternalLink from "../../../../src/components/ExternalLink";
import Playground from "../../../../src/components/Playground";
import TabsLayout from "../../../../src/docs/services/website/tabs.mdx";
import * as React from 'react';
export default {
  Button,
  IconTrash,
  IconAngleRight,
  Notification,
  IconShare,
  Link,
  ExternalLink,
  Playground,
  TabsLayout,
  React
};