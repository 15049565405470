import { Button, Container } from 'hds-react';
import { navigate } from 'gatsby';
import Hero from "../../../../src/components/Hero";
import Image from "../../../../src/components/Image";
import FrontPageProductsList from "../../../../src/components/FrontPageProductsList";
import * as React from 'react';
export default {
  Button,
  Container,
  navigate,
  Hero,
  Image,
  FrontPageProductsList,
  React
};