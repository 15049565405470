import { IconDocument, IconPhone, IconEnvelope, IconPhoto } from 'hds-react';
import PlaygroundPreview from "../../../../src/components/Playground";
import TabsLayout from "../../../../src/docs/components/link/tabs.mdx";
import InternalLink from "../../../../src/components/InternalLink";
import * as React from 'react';
export default {
  IconDocument,
  IconPhone,
  IconEnvelope,
  IconPhoto,
  PlaygroundPreview,
  TabsLayout,
  InternalLink,
  React
};