import { TextInput } from 'hds-react';
import PlaygroundPreview from "../../../../src/components/Playground";
import TabsLayout from "../../../../src/docs/components/text-input/tabs.mdx";
import InternalLink from "../../../../src/components/InternalLink";
import * as React from 'react';
export default {
  TextInput,
  PlaygroundPreview,
  TabsLayout,
  InternalLink,
  React
};