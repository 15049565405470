exports.components = {
  "component---src-docs-404-mdx": () => import("./../../../src/docs/404.mdx" /* webpackChunkName: "component---src-docs-404-mdx" */),
  "component---src-docs-about-accessibility-hds-accessibility-mdx": () => import("./../../../src/docs/about/accessibility/hds-accessibility.mdx" /* webpackChunkName: "component---src-docs-about-accessibility-hds-accessibility-mdx" */),
  "component---src-docs-about-accessibility-statement-mdx": () => import("./../../../src/docs/about/accessibility/statement.mdx" /* webpackChunkName: "component---src-docs-about-accessibility-statement-mdx" */),
  "component---src-docs-about-index-mdx": () => import("./../../../src/docs/about/index.mdx" /* webpackChunkName: "component---src-docs-about-index-mdx" */),
  "component---src-docs-about-resources-mdx": () => import("./../../../src/docs/about/resources.mdx" /* webpackChunkName: "component---src-docs-about-resources-mdx" */),
  "component---src-docs-about-roadmap-mdx": () => import("./../../../src/docs/about/roadmap.mdx" /* webpackChunkName: "component---src-docs-about-roadmap-mdx" */),
  "component---src-docs-about-what-is-new-mdx": () => import("./../../../src/docs/about/what-is-new.mdx" /* webpackChunkName: "component---src-docs-about-what-is-new-mdx" */),
  "component---src-docs-components-accordion-accessibility-mdx": () => import("./../../../src/docs/components/accordion/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-accordion-accessibility-mdx" */),
  "component---src-docs-components-accordion-code-mdx": () => import("./../../../src/docs/components/accordion/code.mdx" /* webpackChunkName: "component---src-docs-components-accordion-code-mdx" */),
  "component---src-docs-components-accordion-customisation-mdx": () => import("./../../../src/docs/components/accordion/customisation.mdx" /* webpackChunkName: "component---src-docs-components-accordion-customisation-mdx" */),
  "component---src-docs-components-accordion-index-mdx": () => import("./../../../src/docs/components/accordion/index.mdx" /* webpackChunkName: "component---src-docs-components-accordion-index-mdx" */),
  "component---src-docs-components-accordion-tabs-mdx": () => import("./../../../src/docs/components/accordion/tabs.mdx" /* webpackChunkName: "component---src-docs-components-accordion-tabs-mdx" */),
  "component---src-docs-components-buttons-accessibility-mdx": () => import("./../../../src/docs/components/buttons/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-buttons-accessibility-mdx" */),
  "component---src-docs-components-buttons-code-mdx": () => import("./../../../src/docs/components/buttons/code.mdx" /* webpackChunkName: "component---src-docs-components-buttons-code-mdx" */),
  "component---src-docs-components-buttons-customisation-mdx": () => import("./../../../src/docs/components/buttons/customisation.mdx" /* webpackChunkName: "component---src-docs-components-buttons-customisation-mdx" */),
  "component---src-docs-components-buttons-index-mdx": () => import("./../../../src/docs/components/buttons/index.mdx" /* webpackChunkName: "component---src-docs-components-buttons-index-mdx" */),
  "component---src-docs-components-buttons-tabs-mdx": () => import("./../../../src/docs/components/buttons/tabs.mdx" /* webpackChunkName: "component---src-docs-components-buttons-tabs-mdx" */),
  "component---src-docs-components-card-accessibility-mdx": () => import("./../../../src/docs/components/card/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-card-accessibility-mdx" */),
  "component---src-docs-components-card-code-mdx": () => import("./../../../src/docs/components/card/code.mdx" /* webpackChunkName: "component---src-docs-components-card-code-mdx" */),
  "component---src-docs-components-card-customisation-mdx": () => import("./../../../src/docs/components/card/customisation.mdx" /* webpackChunkName: "component---src-docs-components-card-customisation-mdx" */),
  "component---src-docs-components-card-index-mdx": () => import("./../../../src/docs/components/card/index.mdx" /* webpackChunkName: "component---src-docs-components-card-index-mdx" */),
  "component---src-docs-components-card-tabs-mdx": () => import("./../../../src/docs/components/card/tabs.mdx" /* webpackChunkName: "component---src-docs-components-card-tabs-mdx" */),
  "component---src-docs-components-checkbox-accessibility-mdx": () => import("./../../../src/docs/components/checkbox/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-checkbox-accessibility-mdx" */),
  "component---src-docs-components-checkbox-code-mdx": () => import("./../../../src/docs/components/checkbox/code.mdx" /* webpackChunkName: "component---src-docs-components-checkbox-code-mdx" */),
  "component---src-docs-components-checkbox-customisation-mdx": () => import("./../../../src/docs/components/checkbox/customisation.mdx" /* webpackChunkName: "component---src-docs-components-checkbox-customisation-mdx" */),
  "component---src-docs-components-checkbox-index-mdx": () => import("./../../../src/docs/components/checkbox/index.mdx" /* webpackChunkName: "component---src-docs-components-checkbox-index-mdx" */),
  "component---src-docs-components-checkbox-tabs-mdx": () => import("./../../../src/docs/components/checkbox/tabs.mdx" /* webpackChunkName: "component---src-docs-components-checkbox-tabs-mdx" */),
  "component---src-docs-components-cookie-consent-accessibility-mdx": () => import("./../../../src/docs/components/cookie-consent/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-cookie-consent-accessibility-mdx" */),
  "component---src-docs-components-cookie-consent-api-mdx": () => import("./../../../src/docs/components/cookie-consent/api.mdx" /* webpackChunkName: "component---src-docs-components-cookie-consent-api-mdx" */),
  "component---src-docs-components-cookie-consent-code-mdx": () => import("./../../../src/docs/components/cookie-consent/code.mdx" /* webpackChunkName: "component---src-docs-components-cookie-consent-code-mdx" */),
  "component---src-docs-components-cookie-consent-index-mdx": () => import("./../../../src/docs/components/cookie-consent/index.mdx" /* webpackChunkName: "component---src-docs-components-cookie-consent-index-mdx" */),
  "component---src-docs-components-cookie-consent-tabs-mdx": () => import("./../../../src/docs/components/cookie-consent/tabs.mdx" /* webpackChunkName: "component---src-docs-components-cookie-consent-tabs-mdx" */),
  "component---src-docs-components-date-input-accessibility-mdx": () => import("./../../../src/docs/components/date-input/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-date-input-accessibility-mdx" */),
  "component---src-docs-components-date-input-code-mdx": () => import("./../../../src/docs/components/date-input/code.mdx" /* webpackChunkName: "component---src-docs-components-date-input-code-mdx" */),
  "component---src-docs-components-date-input-index-mdx": () => import("./../../../src/docs/components/date-input/index.mdx" /* webpackChunkName: "component---src-docs-components-date-input-index-mdx" */),
  "component---src-docs-components-date-input-tabs-mdx": () => import("./../../../src/docs/components/date-input/tabs.mdx" /* webpackChunkName: "component---src-docs-components-date-input-tabs-mdx" */),
  "component---src-docs-components-dialog-accessibility-mdx": () => import("./../../../src/docs/components/dialog/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-dialog-accessibility-mdx" */),
  "component---src-docs-components-dialog-code-mdx": () => import("./../../../src/docs/components/dialog/code.mdx" /* webpackChunkName: "component---src-docs-components-dialog-code-mdx" */),
  "component---src-docs-components-dialog-customisation-mdx": () => import("./../../../src/docs/components/dialog/customisation.mdx" /* webpackChunkName: "component---src-docs-components-dialog-customisation-mdx" */),
  "component---src-docs-components-dialog-index-mdx": () => import("./../../../src/docs/components/dialog/index.mdx" /* webpackChunkName: "component---src-docs-components-dialog-index-mdx" */),
  "component---src-docs-components-dialog-tabs-mdx": () => import("./../../../src/docs/components/dialog/tabs.mdx" /* webpackChunkName: "component---src-docs-components-dialog-tabs-mdx" */),
  "component---src-docs-components-dropdown-accessibility-mdx": () => import("./../../../src/docs/components/dropdown/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-dropdown-accessibility-mdx" */),
  "component---src-docs-components-dropdown-code-mdx": () => import("./../../../src/docs/components/dropdown/code.mdx" /* webpackChunkName: "component---src-docs-components-dropdown-code-mdx" */),
  "component---src-docs-components-dropdown-customisation-mdx": () => import("./../../../src/docs/components/dropdown/customisation.mdx" /* webpackChunkName: "component---src-docs-components-dropdown-customisation-mdx" */),
  "component---src-docs-components-dropdown-index-mdx": () => import("./../../../src/docs/components/dropdown/index.mdx" /* webpackChunkName: "component---src-docs-components-dropdown-index-mdx" */),
  "component---src-docs-components-dropdown-tabs-mdx": () => import("./../../../src/docs/components/dropdown/tabs.mdx" /* webpackChunkName: "component---src-docs-components-dropdown-tabs-mdx" */),
  "component---src-docs-components-fieldset-accessibility-mdx": () => import("./../../../src/docs/components/fieldset/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-fieldset-accessibility-mdx" */),
  "component---src-docs-components-fieldset-code-mdx": () => import("./../../../src/docs/components/fieldset/code.mdx" /* webpackChunkName: "component---src-docs-components-fieldset-code-mdx" */),
  "component---src-docs-components-fieldset-index-mdx": () => import("./../../../src/docs/components/fieldset/index.mdx" /* webpackChunkName: "component---src-docs-components-fieldset-index-mdx" */),
  "component---src-docs-components-fieldset-tabs-mdx": () => import("./../../../src/docs/components/fieldset/tabs.mdx" /* webpackChunkName: "component---src-docs-components-fieldset-tabs-mdx" */),
  "component---src-docs-components-file-input-accessibility-mdx": () => import("./../../../src/docs/components/file-input/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-file-input-accessibility-mdx" */),
  "component---src-docs-components-file-input-code-mdx": () => import("./../../../src/docs/components/file-input/code.mdx" /* webpackChunkName: "component---src-docs-components-file-input-code-mdx" */),
  "component---src-docs-components-file-input-index-mdx": () => import("./../../../src/docs/components/file-input/index.mdx" /* webpackChunkName: "component---src-docs-components-file-input-index-mdx" */),
  "component---src-docs-components-file-input-security-mdx": () => import("./../../../src/docs/components/file-input/security.mdx" /* webpackChunkName: "component---src-docs-components-file-input-security-mdx" */),
  "component---src-docs-components-file-input-tabs-mdx": () => import("./../../../src/docs/components/file-input/tabs.mdx" /* webpackChunkName: "component---src-docs-components-file-input-tabs-mdx" */),
  "component---src-docs-components-footer-accessibility-mdx": () => import("./../../../src/docs/components/footer/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-footer-accessibility-mdx" */),
  "component---src-docs-components-footer-code-mdx": () => import("./../../../src/docs/components/footer/code.mdx" /* webpackChunkName: "component---src-docs-components-footer-code-mdx" */),
  "component---src-docs-components-footer-customisation-mdx": () => import("./../../../src/docs/components/footer/customisation.mdx" /* webpackChunkName: "component---src-docs-components-footer-customisation-mdx" */),
  "component---src-docs-components-footer-index-mdx": () => import("./../../../src/docs/components/footer/index.mdx" /* webpackChunkName: "component---src-docs-components-footer-index-mdx" */),
  "component---src-docs-components-footer-tabs-mdx": () => import("./../../../src/docs/components/footer/tabs.mdx" /* webpackChunkName: "component---src-docs-components-footer-tabs-mdx" */),
  "component---src-docs-components-icon-accessibility-mdx": () => import("./../../../src/docs/components/icon/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-icon-accessibility-mdx" */),
  "component---src-docs-components-icon-code-mdx": () => import("./../../../src/docs/components/icon/code.mdx" /* webpackChunkName: "component---src-docs-components-icon-code-mdx" */),
  "component---src-docs-components-icon-customisation-mdx": () => import("./../../../src/docs/components/icon/customisation.mdx" /* webpackChunkName: "component---src-docs-components-icon-customisation-mdx" */),
  "component---src-docs-components-icon-index-mdx": () => import("./../../../src/docs/components/icon/index.mdx" /* webpackChunkName: "component---src-docs-components-icon-index-mdx" */),
  "component---src-docs-components-icon-tabs-mdx": () => import("./../../../src/docs/components/icon/tabs.mdx" /* webpackChunkName: "component---src-docs-components-icon-tabs-mdx" */),
  "component---src-docs-components-index-mdx": () => import("./../../../src/docs/components/index.mdx" /* webpackChunkName: "component---src-docs-components-index-mdx" */),
  "component---src-docs-components-koros-accessibility-mdx": () => import("./../../../src/docs/components/koros/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-koros-accessibility-mdx" */),
  "component---src-docs-components-koros-code-mdx": () => import("./../../../src/docs/components/koros/code.mdx" /* webpackChunkName: "component---src-docs-components-koros-code-mdx" */),
  "component---src-docs-components-koros-customisation-mdx": () => import("./../../../src/docs/components/koros/customisation.mdx" /* webpackChunkName: "component---src-docs-components-koros-customisation-mdx" */),
  "component---src-docs-components-koros-index-mdx": () => import("./../../../src/docs/components/koros/index.mdx" /* webpackChunkName: "component---src-docs-components-koros-index-mdx" */),
  "component---src-docs-components-koros-tabs-mdx": () => import("./../../../src/docs/components/koros/tabs.mdx" /* webpackChunkName: "component---src-docs-components-koros-tabs-mdx" */),
  "component---src-docs-components-link-accessibility-mdx": () => import("./../../../src/docs/components/link/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-link-accessibility-mdx" */),
  "component---src-docs-components-link-code-mdx": () => import("./../../../src/docs/components/link/code.mdx" /* webpackChunkName: "component---src-docs-components-link-code-mdx" */),
  "component---src-docs-components-link-index-mdx": () => import("./../../../src/docs/components/link/index.mdx" /* webpackChunkName: "component---src-docs-components-link-index-mdx" */),
  "component---src-docs-components-link-tabs-mdx": () => import("./../../../src/docs/components/link/tabs.mdx" /* webpackChunkName: "component---src-docs-components-link-tabs-mdx" */),
  "component---src-docs-components-linkbox-accessibility-mdx": () => import("./../../../src/docs/components/linkbox/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-linkbox-accessibility-mdx" */),
  "component---src-docs-components-linkbox-code-mdx": () => import("./../../../src/docs/components/linkbox/code.mdx" /* webpackChunkName: "component---src-docs-components-linkbox-code-mdx" */),
  "component---src-docs-components-linkbox-index-mdx": () => import("./../../../src/docs/components/linkbox/index.mdx" /* webpackChunkName: "component---src-docs-components-linkbox-index-mdx" */),
  "component---src-docs-components-linkbox-tabs-mdx": () => import("./../../../src/docs/components/linkbox/tabs.mdx" /* webpackChunkName: "component---src-docs-components-linkbox-tabs-mdx" */),
  "component---src-docs-components-loading-spinner-accessibility-mdx": () => import("./../../../src/docs/components/loading-spinner/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-loading-spinner-accessibility-mdx" */),
  "component---src-docs-components-loading-spinner-code-mdx": () => import("./../../../src/docs/components/loading-spinner/code.mdx" /* webpackChunkName: "component---src-docs-components-loading-spinner-code-mdx" */),
  "component---src-docs-components-loading-spinner-customisation-mdx": () => import("./../../../src/docs/components/loading-spinner/customisation.mdx" /* webpackChunkName: "component---src-docs-components-loading-spinner-customisation-mdx" */),
  "component---src-docs-components-loading-spinner-index-mdx": () => import("./../../../src/docs/components/loading-spinner/index.mdx" /* webpackChunkName: "component---src-docs-components-loading-spinner-index-mdx" */),
  "component---src-docs-components-loading-spinner-tabs-mdx": () => import("./../../../src/docs/components/loading-spinner/tabs.mdx" /* webpackChunkName: "component---src-docs-components-loading-spinner-tabs-mdx" */),
  "component---src-docs-components-logo-accessibility-mdx": () => import("./../../../src/docs/components/logo/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-logo-accessibility-mdx" */),
  "component---src-docs-components-logo-code-mdx": () => import("./../../../src/docs/components/logo/code.mdx" /* webpackChunkName: "component---src-docs-components-logo-code-mdx" */),
  "component---src-docs-components-logo-customisation-mdx": () => import("./../../../src/docs/components/logo/customisation.mdx" /* webpackChunkName: "component---src-docs-components-logo-customisation-mdx" */),
  "component---src-docs-components-logo-index-mdx": () => import("./../../../src/docs/components/logo/index.mdx" /* webpackChunkName: "component---src-docs-components-logo-index-mdx" */),
  "component---src-docs-components-logo-tabs-mdx": () => import("./../../../src/docs/components/logo/tabs.mdx" /* webpackChunkName: "component---src-docs-components-logo-tabs-mdx" */),
  "component---src-docs-components-navigation-accessibility-mdx": () => import("./../../../src/docs/components/navigation/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-navigation-accessibility-mdx" */),
  "component---src-docs-components-navigation-code-mdx": () => import("./../../../src/docs/components/navigation/code.mdx" /* webpackChunkName: "component---src-docs-components-navigation-code-mdx" */),
  "component---src-docs-components-navigation-customisation-mdx": () => import("./../../../src/docs/components/navigation/customisation.mdx" /* webpackChunkName: "component---src-docs-components-navigation-customisation-mdx" */),
  "component---src-docs-components-navigation-index-mdx": () => import("./../../../src/docs/components/navigation/index.mdx" /* webpackChunkName: "component---src-docs-components-navigation-index-mdx" */),
  "component---src-docs-components-navigation-tabs-mdx": () => import("./../../../src/docs/components/navigation/tabs.mdx" /* webpackChunkName: "component---src-docs-components-navigation-tabs-mdx" */),
  "component---src-docs-components-notification-accessibility-mdx": () => import("./../../../src/docs/components/notification/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-notification-accessibility-mdx" */),
  "component---src-docs-components-notification-code-mdx": () => import("./../../../src/docs/components/notification/code.mdx" /* webpackChunkName: "component---src-docs-components-notification-code-mdx" */),
  "component---src-docs-components-notification-index-mdx": () => import("./../../../src/docs/components/notification/index.mdx" /* webpackChunkName: "component---src-docs-components-notification-index-mdx" */),
  "component---src-docs-components-notification-tabs-mdx": () => import("./../../../src/docs/components/notification/tabs.mdx" /* webpackChunkName: "component---src-docs-components-notification-tabs-mdx" */),
  "component---src-docs-components-number-input-accessibility-mdx": () => import("./../../../src/docs/components/number-input/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-number-input-accessibility-mdx" */),
  "component---src-docs-components-number-input-code-mdx": () => import("./../../../src/docs/components/number-input/code.mdx" /* webpackChunkName: "component---src-docs-components-number-input-code-mdx" */),
  "component---src-docs-components-number-input-index-mdx": () => import("./../../../src/docs/components/number-input/index.mdx" /* webpackChunkName: "component---src-docs-components-number-input-index-mdx" */),
  "component---src-docs-components-number-input-tabs-mdx": () => import("./../../../src/docs/components/number-input/tabs.mdx" /* webpackChunkName: "component---src-docs-components-number-input-tabs-mdx" */),
  "component---src-docs-components-pagination-accessibility-mdx": () => import("./../../../src/docs/components/pagination/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-pagination-accessibility-mdx" */),
  "component---src-docs-components-pagination-code-mdx": () => import("./../../../src/docs/components/pagination/code.mdx" /* webpackChunkName: "component---src-docs-components-pagination-code-mdx" */),
  "component---src-docs-components-pagination-customisation-mdx": () => import("./../../../src/docs/components/pagination/customisation.mdx" /* webpackChunkName: "component---src-docs-components-pagination-customisation-mdx" */),
  "component---src-docs-components-pagination-index-mdx": () => import("./../../../src/docs/components/pagination/index.mdx" /* webpackChunkName: "component---src-docs-components-pagination-index-mdx" */),
  "component---src-docs-components-pagination-tabs-mdx": () => import("./../../../src/docs/components/pagination/tabs.mdx" /* webpackChunkName: "component---src-docs-components-pagination-tabs-mdx" */),
  "component---src-docs-components-password-input-accessibility-mdx": () => import("./../../../src/docs/components/password-input/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-password-input-accessibility-mdx" */),
  "component---src-docs-components-password-input-code-mdx": () => import("./../../../src/docs/components/password-input/code.mdx" /* webpackChunkName: "component---src-docs-components-password-input-code-mdx" */),
  "component---src-docs-components-password-input-index-mdx": () => import("./../../../src/docs/components/password-input/index.mdx" /* webpackChunkName: "component---src-docs-components-password-input-index-mdx" */),
  "component---src-docs-components-password-input-security-mdx": () => import("./../../../src/docs/components/password-input/security.mdx" /* webpackChunkName: "component---src-docs-components-password-input-security-mdx" */),
  "component---src-docs-components-password-input-tabs-mdx": () => import("./../../../src/docs/components/password-input/tabs.mdx" /* webpackChunkName: "component---src-docs-components-password-input-tabs-mdx" */),
  "component---src-docs-components-phone-input-accessibility-mdx": () => import("./../../../src/docs/components/phone-input/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-phone-input-accessibility-mdx" */),
  "component---src-docs-components-phone-input-code-mdx": () => import("./../../../src/docs/components/phone-input/code.mdx" /* webpackChunkName: "component---src-docs-components-phone-input-code-mdx" */),
  "component---src-docs-components-phone-input-index-mdx": () => import("./../../../src/docs/components/phone-input/index.mdx" /* webpackChunkName: "component---src-docs-components-phone-input-index-mdx" */),
  "component---src-docs-components-phone-input-tabs-mdx": () => import("./../../../src/docs/components/phone-input/tabs.mdx" /* webpackChunkName: "component---src-docs-components-phone-input-tabs-mdx" */),
  "component---src-docs-components-radio-button-accessibility-mdx": () => import("./../../../src/docs/components/radio-button/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-radio-button-accessibility-mdx" */),
  "component---src-docs-components-radio-button-code-mdx": () => import("./../../../src/docs/components/radio-button/code.mdx" /* webpackChunkName: "component---src-docs-components-radio-button-code-mdx" */),
  "component---src-docs-components-radio-button-customisation-mdx": () => import("./../../../src/docs/components/radio-button/customisation.mdx" /* webpackChunkName: "component---src-docs-components-radio-button-customisation-mdx" */),
  "component---src-docs-components-radio-button-index-mdx": () => import("./../../../src/docs/components/radio-button/index.mdx" /* webpackChunkName: "component---src-docs-components-radio-button-index-mdx" */),
  "component---src-docs-components-radio-button-tabs-mdx": () => import("./../../../src/docs/components/radio-button/tabs.mdx" /* webpackChunkName: "component---src-docs-components-radio-button-tabs-mdx" */),
  "component---src-docs-components-search-input-accessibility-mdx": () => import("./../../../src/docs/components/search-input/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-search-input-accessibility-mdx" */),
  "component---src-docs-components-search-input-code-mdx": () => import("./../../../src/docs/components/search-input/code.mdx" /* webpackChunkName: "component---src-docs-components-search-input-code-mdx" */),
  "component---src-docs-components-search-input-index-mdx": () => import("./../../../src/docs/components/search-input/index.mdx" /* webpackChunkName: "component---src-docs-components-search-input-index-mdx" */),
  "component---src-docs-components-search-input-tabs-mdx": () => import("./../../../src/docs/components/search-input/tabs.mdx" /* webpackChunkName: "component---src-docs-components-search-input-tabs-mdx" */),
  "component---src-docs-components-selection-group-accessibility-mdx": () => import("./../../../src/docs/components/selection-group/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-selection-group-accessibility-mdx" */),
  "component---src-docs-components-selection-group-code-mdx": () => import("./../../../src/docs/components/selection-group/code.mdx" /* webpackChunkName: "component---src-docs-components-selection-group-code-mdx" */),
  "component---src-docs-components-selection-group-index-mdx": () => import("./../../../src/docs/components/selection-group/index.mdx" /* webpackChunkName: "component---src-docs-components-selection-group-index-mdx" */),
  "component---src-docs-components-selection-group-tabs-mdx": () => import("./../../../src/docs/components/selection-group/tabs.mdx" /* webpackChunkName: "component---src-docs-components-selection-group-tabs-mdx" */),
  "component---src-docs-components-side-navigation-accessibility-mdx": () => import("./../../../src/docs/components/side-navigation/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-side-navigation-accessibility-mdx" */),
  "component---src-docs-components-side-navigation-code-mdx": () => import("./../../../src/docs/components/side-navigation/code.mdx" /* webpackChunkName: "component---src-docs-components-side-navigation-code-mdx" */),
  "component---src-docs-components-side-navigation-customisation-mdx": () => import("./../../../src/docs/components/side-navigation/customisation.mdx" /* webpackChunkName: "component---src-docs-components-side-navigation-customisation-mdx" */),
  "component---src-docs-components-side-navigation-index-mdx": () => import("./../../../src/docs/components/side-navigation/index.mdx" /* webpackChunkName: "component---src-docs-components-side-navigation-index-mdx" */),
  "component---src-docs-components-side-navigation-tabs-mdx": () => import("./../../../src/docs/components/side-navigation/tabs.mdx" /* webpackChunkName: "component---src-docs-components-side-navigation-tabs-mdx" */),
  "component---src-docs-components-status-label-accessibility-mdx": () => import("./../../../src/docs/components/status-label/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-status-label-accessibility-mdx" */),
  "component---src-docs-components-status-label-code-mdx": () => import("./../../../src/docs/components/status-label/code.mdx" /* webpackChunkName: "component---src-docs-components-status-label-code-mdx" */),
  "component---src-docs-components-status-label-index-mdx": () => import("./../../../src/docs/components/status-label/index.mdx" /* webpackChunkName: "component---src-docs-components-status-label-index-mdx" */),
  "component---src-docs-components-status-label-tabs-mdx": () => import("./../../../src/docs/components/status-label/tabs.mdx" /* webpackChunkName: "component---src-docs-components-status-label-tabs-mdx" */),
  "component---src-docs-components-stepper-accessibility-mdx": () => import("./../../../src/docs/components/stepper/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-stepper-accessibility-mdx" */),
  "component---src-docs-components-stepper-code-mdx": () => import("./../../../src/docs/components/stepper/code.mdx" /* webpackChunkName: "component---src-docs-components-stepper-code-mdx" */),
  "component---src-docs-components-stepper-customisation-mdx": () => import("./../../../src/docs/components/stepper/customisation.mdx" /* webpackChunkName: "component---src-docs-components-stepper-customisation-mdx" */),
  "component---src-docs-components-stepper-index-mdx": () => import("./../../../src/docs/components/stepper/index.mdx" /* webpackChunkName: "component---src-docs-components-stepper-index-mdx" */),
  "component---src-docs-components-stepper-tabs-mdx": () => import("./../../../src/docs/components/stepper/tabs.mdx" /* webpackChunkName: "component---src-docs-components-stepper-tabs-mdx" */),
  "component---src-docs-components-table-accessibility-mdx": () => import("./../../../src/docs/components/table/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-table-accessibility-mdx" */),
  "component---src-docs-components-table-code-mdx": () => import("./../../../src/docs/components/table/code.mdx" /* webpackChunkName: "component---src-docs-components-table-code-mdx" */),
  "component---src-docs-components-table-customisation-mdx": () => import("./../../../src/docs/components/table/customisation.mdx" /* webpackChunkName: "component---src-docs-components-table-customisation-mdx" */),
  "component---src-docs-components-table-index-mdx": () => import("./../../../src/docs/components/table/index.mdx" /* webpackChunkName: "component---src-docs-components-table-index-mdx" */),
  "component---src-docs-components-table-tabs-mdx": () => import("./../../../src/docs/components/table/tabs.mdx" /* webpackChunkName: "component---src-docs-components-table-tabs-mdx" */),
  "component---src-docs-components-tabs-accessibility-mdx": () => import("./../../../src/docs/components/tabs/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-tabs-accessibility-mdx" */),
  "component---src-docs-components-tabs-code-mdx": () => import("./../../../src/docs/components/tabs/code.mdx" /* webpackChunkName: "component---src-docs-components-tabs-code-mdx" */),
  "component---src-docs-components-tabs-customisation-mdx": () => import("./../../../src/docs/components/tabs/customisation.mdx" /* webpackChunkName: "component---src-docs-components-tabs-customisation-mdx" */),
  "component---src-docs-components-tabs-index-mdx": () => import("./../../../src/docs/components/tabs/index.mdx" /* webpackChunkName: "component---src-docs-components-tabs-index-mdx" */),
  "component---src-docs-components-tabs-tabs-mdx": () => import("./../../../src/docs/components/tabs/tabs.mdx" /* webpackChunkName: "component---src-docs-components-tabs-tabs-mdx" */),
  "component---src-docs-components-tag-accessibility-mdx": () => import("./../../../src/docs/components/tag/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-tag-accessibility-mdx" */),
  "component---src-docs-components-tag-code-mdx": () => import("./../../../src/docs/components/tag/code.mdx" /* webpackChunkName: "component---src-docs-components-tag-code-mdx" */),
  "component---src-docs-components-tag-customisation-mdx": () => import("./../../../src/docs/components/tag/customisation.mdx" /* webpackChunkName: "component---src-docs-components-tag-customisation-mdx" */),
  "component---src-docs-components-tag-index-mdx": () => import("./../../../src/docs/components/tag/index.mdx" /* webpackChunkName: "component---src-docs-components-tag-index-mdx" */),
  "component---src-docs-components-tag-tabs-mdx": () => import("./../../../src/docs/components/tag/tabs.mdx" /* webpackChunkName: "component---src-docs-components-tag-tabs-mdx" */),
  "component---src-docs-components-text-area-accessibility-mdx": () => import("./../../../src/docs/components/text-area/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-text-area-accessibility-mdx" */),
  "component---src-docs-components-text-area-code-mdx": () => import("./../../../src/docs/components/text-area/code.mdx" /* webpackChunkName: "component---src-docs-components-text-area-code-mdx" */),
  "component---src-docs-components-text-area-index-mdx": () => import("./../../../src/docs/components/text-area/index.mdx" /* webpackChunkName: "component---src-docs-components-text-area-index-mdx" */),
  "component---src-docs-components-text-area-tabs-mdx": () => import("./../../../src/docs/components/text-area/tabs.mdx" /* webpackChunkName: "component---src-docs-components-text-area-tabs-mdx" */),
  "component---src-docs-components-text-input-accessibility-mdx": () => import("./../../../src/docs/components/text-input/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-text-input-accessibility-mdx" */),
  "component---src-docs-components-text-input-code-mdx": () => import("./../../../src/docs/components/text-input/code.mdx" /* webpackChunkName: "component---src-docs-components-text-input-code-mdx" */),
  "component---src-docs-components-text-input-index-mdx": () => import("./../../../src/docs/components/text-input/index.mdx" /* webpackChunkName: "component---src-docs-components-text-input-index-mdx" */),
  "component---src-docs-components-text-input-tabs-mdx": () => import("./../../../src/docs/components/text-input/tabs.mdx" /* webpackChunkName: "component---src-docs-components-text-input-tabs-mdx" */),
  "component---src-docs-components-time-input-accessibility-mdx": () => import("./../../../src/docs/components/time-input/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-time-input-accessibility-mdx" */),
  "component---src-docs-components-time-input-code-mdx": () => import("./../../../src/docs/components/time-input/code.mdx" /* webpackChunkName: "component---src-docs-components-time-input-code-mdx" */),
  "component---src-docs-components-time-input-index-mdx": () => import("./../../../src/docs/components/time-input/index.mdx" /* webpackChunkName: "component---src-docs-components-time-input-index-mdx" */),
  "component---src-docs-components-time-input-tabs-mdx": () => import("./../../../src/docs/components/time-input/tabs.mdx" /* webpackChunkName: "component---src-docs-components-time-input-tabs-mdx" */),
  "component---src-docs-components-toggle-button-accessibility-mdx": () => import("./../../../src/docs/components/toggle-button/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-toggle-button-accessibility-mdx" */),
  "component---src-docs-components-toggle-button-code-mdx": () => import("./../../../src/docs/components/toggle-button/code.mdx" /* webpackChunkName: "component---src-docs-components-toggle-button-code-mdx" */),
  "component---src-docs-components-toggle-button-customisation-mdx": () => import("./../../../src/docs/components/toggle-button/customisation.mdx" /* webpackChunkName: "component---src-docs-components-toggle-button-customisation-mdx" */),
  "component---src-docs-components-toggle-button-index-mdx": () => import("./../../../src/docs/components/toggle-button/index.mdx" /* webpackChunkName: "component---src-docs-components-toggle-button-index-mdx" */),
  "component---src-docs-components-toggle-button-tabs-mdx": () => import("./../../../src/docs/components/toggle-button/tabs.mdx" /* webpackChunkName: "component---src-docs-components-toggle-button-tabs-mdx" */),
  "component---src-docs-components-tooltip-accessibility-mdx": () => import("./../../../src/docs/components/tooltip/accessibility.mdx" /* webpackChunkName: "component---src-docs-components-tooltip-accessibility-mdx" */),
  "component---src-docs-components-tooltip-code-mdx": () => import("./../../../src/docs/components/tooltip/code.mdx" /* webpackChunkName: "component---src-docs-components-tooltip-code-mdx" */),
  "component---src-docs-components-tooltip-index-mdx": () => import("./../../../src/docs/components/tooltip/index.mdx" /* webpackChunkName: "component---src-docs-components-tooltip-index-mdx" */),
  "component---src-docs-components-tooltip-tabs-mdx": () => import("./../../../src/docs/components/tooltip/tabs.mdx" /* webpackChunkName: "component---src-docs-components-tooltip-tabs-mdx" */),
  "component---src-docs-contact-accessibility-hds-accessibility-mdx": () => import("./../../../src/docs/contact/accessibility/hds-accessibility.mdx" /* webpackChunkName: "component---src-docs-contact-accessibility-hds-accessibility-mdx" */),
  "component---src-docs-contact-accessibility-statement-mdx": () => import("./../../../src/docs/contact/accessibility/statement.mdx" /* webpackChunkName: "component---src-docs-contact-accessibility-statement-mdx" */),
  "component---src-docs-contact-index-mdx": () => import("./../../../src/docs/contact/index.mdx" /* webpackChunkName: "component---src-docs-contact-index-mdx" */),
  "component---src-docs-contact-jobs-mdx": () => import("./../../../src/docs/contact/jobs.mdx" /* webpackChunkName: "component---src-docs-contact-jobs-mdx" */),
  "component---src-docs-contact-my-mdx": () => import("./../../../src/docs/contact/my.mdx" /* webpackChunkName: "component---src-docs-contact-my-mdx" */),
  "component---src-docs-contact-resources-mdx": () => import("./../../../src/docs/contact/resources.mdx" /* webpackChunkName: "component---src-docs-contact-resources-mdx" */),
  "component---src-docs-contact-roadmap-mdx": () => import("./../../../src/docs/contact/roadmap.mdx" /* webpackChunkName: "component---src-docs-contact-roadmap-mdx" */),
  "component---src-docs-contact-what-is-new-mdx": () => import("./../../../src/docs/contact/what-is-new.mdx" /* webpackChunkName: "component---src-docs-contact-what-is-new-mdx" */),
  "component---src-docs-erpnext-ac-ac-mdx": () => import("./../../../src/docs/erpnext/ac/ac.mdx" /* webpackChunkName: "component---src-docs-erpnext-ac-ac-mdx" */),
  "component---src-docs-erpnext-ac-closing-books-mdx": () => import("./../../../src/docs/erpnext/ac/closing-books.mdx" /* webpackChunkName: "component---src-docs-erpnext-ac-closing-books-mdx" */),
  "component---src-docs-erpnext-ac-coa-mdx": () => import("./../../../src/docs/erpnext/ac/coa.mdx" /* webpackChunkName: "component---src-docs-erpnext-ac-coa-mdx" */),
  "component---src-docs-erpnext-ac-costcentres-and-accountingdimensions-mdx": () => import("./../../../src/docs/erpnext/ac/costcentres-and-accountingdimensions.mdx" /* webpackChunkName: "component---src-docs-erpnext-ac-costcentres-and-accountingdimensions-mdx" */),
  "component---src-docs-erpnext-ac-debit-and-creditnote-mdx": () => import("./../../../src/docs/erpnext/ac/debit-and-creditnote.mdx" /* webpackChunkName: "component---src-docs-erpnext-ac-debit-and-creditnote-mdx" */),
  "component---src-docs-erpnext-ac-deferredrevenue-and-expenses-mdx": () => import("./../../../src/docs/erpnext/ac/deferredrevenue-and-expenses.mdx" /* webpackChunkName: "component---src-docs-erpnext-ac-deferredrevenue-and-expenses-mdx" */),
  "component---src-docs-erpnext-ac-invoicing-mdx": () => import("./../../../src/docs/erpnext/ac/invoicing.mdx" /* webpackChunkName: "component---src-docs-erpnext-ac-invoicing-mdx" */),
  "component---src-docs-erpnext-ac-journalentry-and-payments-mdx": () => import("./../../../src/docs/erpnext/ac/journalentry-and-payments.mdx" /* webpackChunkName: "component---src-docs-erpnext-ac-journalentry-and-payments-mdx" */),
  "component---src-docs-erpnext-ac-multi-company-setup-mdx": () => import("./../../../src/docs/erpnext/ac/multi-company-setup.mdx" /* webpackChunkName: "component---src-docs-erpnext-ac-multi-company-setup-mdx" */),
  "component---src-docs-erpnext-ac-multi-currency-accounting-mdx": () => import("./../../../src/docs/erpnext/ac/multi-currency-accounting.mdx" /* webpackChunkName: "component---src-docs-erpnext-ac-multi-currency-accounting-mdx" */),
  "component---src-docs-erpnext-ac-open-balance-mdx": () => import("./../../../src/docs/erpnext/ac/open-balance.mdx" /* webpackChunkName: "component---src-docs-erpnext-ac-open-balance-mdx" */),
  "component---src-docs-erpnext-ac-payment-entry-mdx": () => import("./../../../src/docs/erpnext/ac/payment-entry.mdx" /* webpackChunkName: "component---src-docs-erpnext-ac-payment-entry-mdx" */),
  "component---src-docs-erpnext-ac-payment-requests-mdx": () => import("./../../../src/docs/erpnext/ac/payment-requests.mdx" /* webpackChunkName: "component---src-docs-erpnext-ac-payment-requests-mdx" */),
  "component---src-docs-erpnext-ac-payment-terms-mdx": () => import("./../../../src/docs/erpnext/ac/payment-terms.mdx" /* webpackChunkName: "component---src-docs-erpnext-ac-payment-terms-mdx" */),
  "component---src-docs-erpnext-ac-payment-tools-mdx": () => import("./../../../src/docs/erpnext/ac/payment-tools.mdx" /* webpackChunkName: "component---src-docs-erpnext-ac-payment-tools-mdx" */),
  "component---src-docs-erpnext-ac-reports-mdx": () => import("./../../../src/docs/erpnext/ac/reports.mdx" /* webpackChunkName: "component---src-docs-erpnext-ac-reports-mdx" */),
  "component---src-docs-erpnext-ac-shareholder-management-mdx": () => import("./../../../src/docs/erpnext/ac/shareholder-management.mdx" /* webpackChunkName: "component---src-docs-erpnext-ac-shareholder-management-mdx" */),
  "component---src-docs-erpnext-ac-subscription-management-mdx": () => import("./../../../src/docs/erpnext/ac/subscription-management.mdx" /* webpackChunkName: "component---src-docs-erpnext-ac-subscription-management-mdx" */),
  "component---src-docs-erpnext-ac-taxes-mdx": () => import("./../../../src/docs/erpnext/ac/taxes.mdx" /* webpackChunkName: "component---src-docs-erpnext-ac-taxes-mdx" */),
  "component---src-docs-erpnext-ac-taxwithholding-category-mdx": () => import("./../../../src/docs/erpnext/ac/taxwithholding-category.mdx" /* webpackChunkName: "component---src-docs-erpnext-ac-taxwithholding-category-mdx" */),
  "component---src-docs-erpnext-index-mdx": () => import("./../../../src/docs/erpnext/index.mdx" /* webpackChunkName: "component---src-docs-erpnext-index-mdx" */),
  "component---src-docs-erpnext-resources-mdx": () => import("./../../../src/docs/erpnext/resources.mdx" /* webpackChunkName: "component---src-docs-erpnext-resources-mdx" */),
  "component---src-docs-erpnext-roadmap-mdx": () => import("./../../../src/docs/erpnext/roadmap.mdx" /* webpackChunkName: "component---src-docs-erpnext-roadmap-mdx" */),
  "component---src-docs-erpnext-what-is-new-mdx": () => import("./../../../src/docs/erpnext/what-is-new.mdx" /* webpackChunkName: "component---src-docs-erpnext-what-is-new-mdx" */),
  "component---src-docs-foundation-design-tokens-breakpoints-index-mdx": () => import("./../../../src/docs/foundation/design-tokens/breakpoints/index.mdx" /* webpackChunkName: "component---src-docs-foundation-design-tokens-breakpoints-index-mdx" */),
  "component---src-docs-foundation-design-tokens-breakpoints-tabs-mdx": () => import("./../../../src/docs/foundation/design-tokens/breakpoints/tabs.mdx" /* webpackChunkName: "component---src-docs-foundation-design-tokens-breakpoints-tabs-mdx" */),
  "component---src-docs-foundation-design-tokens-breakpoints-tokens-mdx": () => import("./../../../src/docs/foundation/design-tokens/breakpoints/tokens.mdx" /* webpackChunkName: "component---src-docs-foundation-design-tokens-breakpoints-tokens-mdx" */),
  "component---src-docs-foundation-design-tokens-colour-accessibility-mdx": () => import("./../../../src/docs/foundation/design-tokens/colour/accessibility.mdx" /* webpackChunkName: "component---src-docs-foundation-design-tokens-colour-accessibility-mdx" */),
  "component---src-docs-foundation-design-tokens-colour-index-mdx": () => import("./../../../src/docs/foundation/design-tokens/colour/index.mdx" /* webpackChunkName: "component---src-docs-foundation-design-tokens-colour-index-mdx" */),
  "component---src-docs-foundation-design-tokens-colour-tabs-mdx": () => import("./../../../src/docs/foundation/design-tokens/colour/tabs.mdx" /* webpackChunkName: "component---src-docs-foundation-design-tokens-colour-tabs-mdx" */),
  "component---src-docs-foundation-design-tokens-colour-tokens-mdx": () => import("./../../../src/docs/foundation/design-tokens/colour/tokens.mdx" /* webpackChunkName: "component---src-docs-foundation-design-tokens-colour-tokens-mdx" */),
  "component---src-docs-foundation-design-tokens-spacing-index-mdx": () => import("./../../../src/docs/foundation/design-tokens/spacing/index.mdx" /* webpackChunkName: "component---src-docs-foundation-design-tokens-spacing-index-mdx" */),
  "component---src-docs-foundation-design-tokens-spacing-tabs-mdx": () => import("./../../../src/docs/foundation/design-tokens/spacing/tabs.mdx" /* webpackChunkName: "component---src-docs-foundation-design-tokens-spacing-tabs-mdx" */),
  "component---src-docs-foundation-design-tokens-spacing-tokens-mdx": () => import("./../../../src/docs/foundation/design-tokens/spacing/tokens.mdx" /* webpackChunkName: "component---src-docs-foundation-design-tokens-spacing-tokens-mdx" */),
  "component---src-docs-foundation-design-tokens-typography-index-mdx": () => import("./../../../src/docs/foundation/design-tokens/typography/index.mdx" /* webpackChunkName: "component---src-docs-foundation-design-tokens-typography-index-mdx" */),
  "component---src-docs-foundation-design-tokens-typography-tabs-mdx": () => import("./../../../src/docs/foundation/design-tokens/typography/tabs.mdx" /* webpackChunkName: "component---src-docs-foundation-design-tokens-typography-tabs-mdx" */),
  "component---src-docs-foundation-design-tokens-typography-tokens-mdx": () => import("./../../../src/docs/foundation/design-tokens/typography/tokens.mdx" /* webpackChunkName: "component---src-docs-foundation-design-tokens-typography-tokens-mdx" */),
  "component---src-docs-foundation-guidelines-checkbox-radiobutton-toggle-mdx": () => import("./../../../src/docs/foundation/guidelines/checkbox-radiobutton-toggle.mdx" /* webpackChunkName: "component---src-docs-foundation-guidelines-checkbox-radiobutton-toggle-mdx" */),
  "component---src-docs-foundation-guidelines-data-formats-mdx": () => import("./../../../src/docs/foundation/guidelines/data-formats.mdx" /* webpackChunkName: "component---src-docs-foundation-guidelines-data-formats-mdx" */),
  "component---src-docs-foundation-guidelines-grid-mdx": () => import("./../../../src/docs/foundation/guidelines/grid.mdx" /* webpackChunkName: "component---src-docs-foundation-guidelines-grid-mdx" */),
  "component---src-docs-foundation-guidelines-inclusivity-mdx": () => import("./../../../src/docs/foundation/guidelines/inclusivity.mdx" /* webpackChunkName: "component---src-docs-foundation-guidelines-inclusivity-mdx" */),
  "component---src-docs-foundation-guidelines-localisation-mdx": () => import("./../../../src/docs/foundation/guidelines/localisation.mdx" /* webpackChunkName: "component---src-docs-foundation-guidelines-localisation-mdx" */),
  "component---src-docs-foundation-guidelines-photography-mdx": () => import("./../../../src/docs/foundation/guidelines/photography.mdx" /* webpackChunkName: "component---src-docs-foundation-guidelines-photography-mdx" */),
  "component---src-docs-foundation-guidelines-server-side-rendering-mdx": () => import("./../../../src/docs/foundation/guidelines/server-side-rendering.mdx" /* webpackChunkName: "component---src-docs-foundation-guidelines-server-side-rendering-mdx" */),
  "component---src-docs-foundation-index-mdx": () => import("./../../../src/docs/foundation/index.mdx" /* webpackChunkName: "component---src-docs-foundation-index-mdx" */),
  "component---src-docs-foundation-visual-assets-favicon-mdx": () => import("./../../../src/docs/foundation/visual-assets/favicon.mdx" /* webpackChunkName: "component---src-docs-foundation-visual-assets-favicon-mdx" */),
  "component---src-docs-foundation-visual-assets-icons-index-mdx": () => import("./../../../src/docs/foundation/visual-assets/icons/index.mdx" /* webpackChunkName: "component---src-docs-foundation-visual-assets-icons-index-mdx" */),
  "component---src-docs-foundation-visual-assets-icons-list-mdx": () => import("./../../../src/docs/foundation/visual-assets/icons/list.mdx" /* webpackChunkName: "component---src-docs-foundation-visual-assets-icons-list-mdx" */),
  "component---src-docs-foundation-visual-assets-icons-tabs-mdx": () => import("./../../../src/docs/foundation/visual-assets/icons/tabs.mdx" /* webpackChunkName: "component---src-docs-foundation-visual-assets-icons-tabs-mdx" */),
  "component---src-docs-foundation-visual-assets-logo-mdx": () => import("./../../../src/docs/foundation/visual-assets/logo.mdx" /* webpackChunkName: "component---src-docs-foundation-visual-assets-logo-mdx" */),
  "component---src-docs-foundation-visual-assets-placeholders-mdx": () => import("./../../../src/docs/foundation/visual-assets/placeholders.mdx" /* webpackChunkName: "component---src-docs-foundation-visual-assets-placeholders-mdx" */),
  "component---src-docs-foundation-visual-assets-wave-motifs-mdx": () => import("./../../../src/docs/foundation/visual-assets/wave-motifs.mdx" /* webpackChunkName: "component---src-docs-foundation-visual-assets-wave-motifs-mdx" */),
  "component---src-docs-getting-started-contributing-before-contributing-mdx": () => import("./../../../src/docs/getting-started/contributing/before-contributing.mdx" /* webpackChunkName: "component---src-docs-getting-started-contributing-before-contributing-mdx" */),
  "component---src-docs-getting-started-contributing-design-mdx": () => import("./../../../src/docs/getting-started/contributing/design.mdx" /* webpackChunkName: "component---src-docs-getting-started-contributing-design-mdx" */),
  "component---src-docs-getting-started-contributing-documentation-mdx": () => import("./../../../src/docs/getting-started/contributing/documentation.mdx" /* webpackChunkName: "component---src-docs-getting-started-contributing-documentation-mdx" */),
  "component---src-docs-getting-started-contributing-icons-mdx": () => import("./../../../src/docs/getting-started/contributing/icons.mdx" /* webpackChunkName: "component---src-docs-getting-started-contributing-icons-mdx" */),
  "component---src-docs-getting-started-contributing-implementation-mdx": () => import("./../../../src/docs/getting-started/contributing/implementation.mdx" /* webpackChunkName: "component---src-docs-getting-started-contributing-implementation-mdx" */),
  "component---src-docs-getting-started-designer-mdx": () => import("./../../../src/docs/getting-started/designer.mdx" /* webpackChunkName: "component---src-docs-getting-started-designer-mdx" */),
  "component---src-docs-getting-started-developer-mdx": () => import("./../../../src/docs/getting-started/developer.mdx" /* webpackChunkName: "component---src-docs-getting-started-developer-mdx" */),
  "component---src-docs-getting-started-faq-accessibility-mdx": () => import("./../../../src/docs/getting-started/faq/accessibility.mdx" /* webpackChunkName: "component---src-docs-getting-started-faq-accessibility-mdx" */),
  "component---src-docs-getting-started-faq-code-mdx": () => import("./../../../src/docs/getting-started/faq/code.mdx" /* webpackChunkName: "component---src-docs-getting-started-faq-code-mdx" */),
  "component---src-docs-getting-started-faq-design-mdx": () => import("./../../../src/docs/getting-started/faq/design.mdx" /* webpackChunkName: "component---src-docs-getting-started-faq-design-mdx" */),
  "component---src-docs-getting-started-faq-index-mdx": () => import("./../../../src/docs/getting-started/faq/index.mdx" /* webpackChunkName: "component---src-docs-getting-started-faq-index-mdx" */),
  "component---src-docs-getting-started-faq-tabs-mdx": () => import("./../../../src/docs/getting-started/faq/tabs.mdx" /* webpackChunkName: "component---src-docs-getting-started-faq-tabs-mdx" */),
  "component---src-docs-getting-started-hds-2-0-fi-mika-on-hds-2-0-mdx": () => import("./../../../src/docs/getting-started/hds-2.0/fi/mika-on-hds-2.0.mdx" /* webpackChunkName: "component---src-docs-getting-started-hds-2-0-fi-mika-on-hds-2-0-mdx" */),
  "component---src-docs-getting-started-hds-2-0-migrating-to-2-0-mdx": () => import("./../../../src/docs/getting-started/hds-2.0/migrating-to-2.0.mdx" /* webpackChunkName: "component---src-docs-getting-started-hds-2-0-migrating-to-2-0-mdx" */),
  "component---src-docs-getting-started-hds-2-0-what-is-hds-2-0-mdx": () => import("./../../../src/docs/getting-started/hds-2.0/what-is-hds-2.0.mdx" /* webpackChunkName: "component---src-docs-getting-started-hds-2-0-what-is-hds-2-0-mdx" */),
  "component---src-docs-getting-started-index-mdx": () => import("./../../../src/docs/getting-started/index.mdx" /* webpackChunkName: "component---src-docs-getting-started-index-mdx" */),
  "component---src-docs-getting-started-my-mdx": () => import("./../../../src/docs/getting-started/my.mdx" /* webpackChunkName: "component---src-docs-getting-started-my-mdx" */),
  "component---src-docs-getting-started-pagewithimage-mdx": () => import("./../../../src/docs/getting-started/pagewithimage.mdx" /* webpackChunkName: "component---src-docs-getting-started-pagewithimage-mdx" */),
  "component---src-docs-getting-started-tutorials-abstract-tutorial-mdx": () => import("./../../../src/docs/getting-started/tutorials/abstract-tutorial.mdx" /* webpackChunkName: "component---src-docs-getting-started-tutorials-abstract-tutorial-mdx" */),
  "component---src-docs-home-index-mdx": () => import("./../../../src/docs/home/index.mdx" /* webpackChunkName: "component---src-docs-home-index-mdx" */),
  "component---src-docs-index-mdx": () => import("./../../../src/docs/index.mdx" /* webpackChunkName: "component---src-docs-index-mdx" */),
  "component---src-docs-patterns-cookies-basics-mdx": () => import("./../../../src/docs/patterns/cookies/basics.mdx" /* webpackChunkName: "component---src-docs-patterns-cookies-basics-mdx" */),
  "component---src-docs-patterns-cookies-common-helsinki-cookies-mdx": () => import("./../../../src/docs/patterns/cookies/common-helsinki-cookies.mdx" /* webpackChunkName: "component---src-docs-patterns-cookies-common-helsinki-cookies-mdx" */),
  "component---src-docs-patterns-cookies-using-the-cookieconsent-mdx": () => import("./../../../src/docs/patterns/cookies/using-the-cookieconsent.mdx" /* webpackChunkName: "component---src-docs-patterns-cookies-using-the-cookieconsent-mdx" */),
  "component---src-docs-patterns-forms-form-building-mdx": () => import("./../../../src/docs/patterns/forms/form-building.mdx" /* webpackChunkName: "component---src-docs-patterns-forms-form-building-mdx" */),
  "component---src-docs-patterns-forms-form-multi-page-mdx": () => import("./../../../src/docs/patterns/forms/form-multi-page.mdx" /* webpackChunkName: "component---src-docs-patterns-forms-form-multi-page-mdx" */),
  "component---src-docs-patterns-forms-form-validation-mdx": () => import("./../../../src/docs/patterns/forms/form-validation.mdx" /* webpackChunkName: "component---src-docs-patterns-forms-form-validation-mdx" */),
  "component---src-docs-patterns-index-mdx": () => import("./../../../src/docs/patterns/index.mdx" /* webpackChunkName: "component---src-docs-patterns-index-mdx" */),
  "component---src-docs-services-erpnext-accessibility-mdx": () => import("./../../../src/docs/services/erpnext/accessibility.mdx" /* webpackChunkName: "component---src-docs-services-erpnext-accessibility-mdx" */),
  "component---src-docs-services-erpnext-code-mdx": () => import("./../../../src/docs/services/erpnext/code.mdx" /* webpackChunkName: "component---src-docs-services-erpnext-code-mdx" */),
  "component---src-docs-services-erpnext-customisation-mdx": () => import("./../../../src/docs/services/erpnext/customisation.mdx" /* webpackChunkName: "component---src-docs-services-erpnext-customisation-mdx" */),
  "component---src-docs-services-erpnext-index-mdx": () => import("./../../../src/docs/services/erpnext/index.mdx" /* webpackChunkName: "component---src-docs-services-erpnext-index-mdx" */),
  "component---src-docs-services-erpnext-tabs-mdx": () => import("./../../../src/docs/services/erpnext/tabs.mdx" /* webpackChunkName: "component---src-docs-services-erpnext-tabs-mdx" */),
  "component---src-docs-services-index-mdx": () => import("./../../../src/docs/services/index.mdx" /* webpackChunkName: "component---src-docs-services-index-mdx" */),
  "component---src-docs-services-mobile-accessibility-mdx": () => import("./../../../src/docs/services/mobile/accessibility.mdx" /* webpackChunkName: "component---src-docs-services-mobile-accessibility-mdx" */),
  "component---src-docs-services-mobile-code-mdx": () => import("./../../../src/docs/services/mobile/code.mdx" /* webpackChunkName: "component---src-docs-services-mobile-code-mdx" */),
  "component---src-docs-services-mobile-customisation-mdx": () => import("./../../../src/docs/services/mobile/customisation.mdx" /* webpackChunkName: "component---src-docs-services-mobile-customisation-mdx" */),
  "component---src-docs-services-mobile-index-mdx": () => import("./../../../src/docs/services/mobile/index.mdx" /* webpackChunkName: "component---src-docs-services-mobile-index-mdx" */),
  "component---src-docs-services-mobile-tabs-mdx": () => import("./../../../src/docs/services/mobile/tabs.mdx" /* webpackChunkName: "component---src-docs-services-mobile-tabs-mdx" */),
  "component---src-docs-services-seree-accessibility-mdx": () => import("./../../../src/docs/services/seree/accessibility.mdx" /* webpackChunkName: "component---src-docs-services-seree-accessibility-mdx" */),
  "component---src-docs-services-seree-code-mdx": () => import("./../../../src/docs/services/seree/code.mdx" /* webpackChunkName: "component---src-docs-services-seree-code-mdx" */),
  "component---src-docs-services-seree-customisation-mdx": () => import("./../../../src/docs/services/seree/customisation.mdx" /* webpackChunkName: "component---src-docs-services-seree-customisation-mdx" */),
  "component---src-docs-services-seree-index-mdx": () => import("./../../../src/docs/services/seree/index.mdx" /* webpackChunkName: "component---src-docs-services-seree-index-mdx" */),
  "component---src-docs-services-seree-tabs-mdx": () => import("./../../../src/docs/services/seree/tabs.mdx" /* webpackChunkName: "component---src-docs-services-seree-tabs-mdx" */),
  "component---src-docs-services-website-accessibility-mdx": () => import("./../../../src/docs/services/website/accessibility.mdx" /* webpackChunkName: "component---src-docs-services-website-accessibility-mdx" */),
  "component---src-docs-services-website-code-mdx": () => import("./../../../src/docs/services/website/code.mdx" /* webpackChunkName: "component---src-docs-services-website-code-mdx" */),
  "component---src-docs-services-website-customisation-mdx": () => import("./../../../src/docs/services/website/customisation.mdx" /* webpackChunkName: "component---src-docs-services-website-customisation-mdx" */),
  "component---src-docs-services-website-index-mdx": () => import("./../../../src/docs/services/website/index.mdx" /* webpackChunkName: "component---src-docs-services-website-index-mdx" */),
  "component---src-docs-services-website-tabs-mdx": () => import("./../../../src/docs/services/website/tabs.mdx" /* webpackChunkName: "component---src-docs-services-website-tabs-mdx" */)
}

