import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LeadParagraph from '../../../../components/LeadParagraph';
import Layout from '../../../../components/layout';
import PageTabs from '../../../../components/PageTabs';
export const _frontmatter = {
  "slug": "/foundation/visual-assets/icons/tabs",
  "title": "Icons",
  "navTitle": "Icons"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  pageContext,
  children
}) => <Layout pageContext={pageContext}>{children}</Layout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "icons",
      "style": {
        "position": "relative"
      }
    }}>{`Icons`}<a parentName="h1" {...{
        "href": "#icons",
        "aria-label": "icons permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
  The HDS icon library is a set of icons specially designed for use in digital user interfaces.
    </LeadParagraph>
    <PageTabs pageContext={props.pageContext} mdxType="PageTabs">
  <PageTabs.TabList>
    <PageTabs.Tab href="/">Usage</PageTabs.Tab>
    <PageTabs.Tab href="/list">Icons</PageTabs.Tab>
  </PageTabs.TabList>
  <PageTabs.TabPanel>{props.children}</PageTabs.TabPanel>
    </PageTabs>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      