import { useState } from 'react';
import { Table } from 'hds-react';
import PlaygroundPreview from "../../../../src/components/Playground";
import TabsLayout from "../../../../src/docs/components/table/tabs.mdx";
import InternalLink from "../../../../src/components/InternalLink";
import * as React from 'react';
export default {
  useState,
  Table,
  PlaygroundPreview,
  TabsLayout,
  InternalLink,
  React
};