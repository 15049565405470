import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { StatusLabel } from 'hds-react';
import LeadParagraph from '../../../components/LeadParagraph';
import Layout from '../../../components/layout';
import PageTabs from '../../../components/PageTabs';
export const _frontmatter = {
  "slug": "/components/logo/tabs",
  "title": "Logo"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  pageContext,
  children
}) => <Layout pageContext={pageContext}>{children}</Layout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "logo",
      "style": {
        "position": "relative"
      }
    }}>{`Logo`}<a parentName="h1" {...{
        "href": "#logo",
        "aria-label": "logo permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <StatusLabel type="info" mdxType="StatusLabel">Stable</StatusLabel>
    <StatusLabel type="success" style={{
      marginLeft: 'var(--spacing-xs)'
    }} mdxType="StatusLabel">
  Accessible
    </StatusLabel>
    <LeadParagraph mdxType="LeadParagraph">
  The logo is used to identify the site or application as an official City of Helsinki service.
    </LeadParagraph>
    <PageTabs pageContext={props.pageContext} mdxType="PageTabs">
  <PageTabs.TabList>
    <PageTabs.Tab href="/">Usage</PageTabs.Tab>
    <PageTabs.Tab href="/code">Code</PageTabs.Tab>
    <PageTabs.Tab href="/accessibility">Accessibility</PageTabs.Tab>
    <PageTabs.Tab href="/customisation">Customisation</PageTabs.Tab>
  </PageTabs.TabList>
  <PageTabs.TabPanel>{props.children}</PageTabs.TabPanel>
    </PageTabs>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      