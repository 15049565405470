import { Checkbox, Notification } from 'hds-react';
import Playground from "../../../../src/components/Playground";
import TabsLayout from "../../../../src/docs/components/checkbox/tabs.mdx";
import * as React from 'react';
export default {
  Checkbox,
  Notification,
  Playground,
  TabsLayout,
  React
};