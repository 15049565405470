import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { StatusLabel, Notification } from 'hds-react';
import LeadParagraph from '../../../components/LeadParagraph';
import Layout from '../../../components/layout';
import PageTabs from '../../../components/PageTabs';
export const _frontmatter = {
  "slug": "/components/table/tabs",
  "title": "Table"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  pageContext,
  children
}) => <Layout pageContext={pageContext}>{children}</Layout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "table",
      "style": {
        "position": "relative"
      }
    }}>{`Table`}<a parentName="h1" {...{
        "href": "#table",
        "aria-label": "table permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <StatusLabel type="info" mdxType="StatusLabel">Stable</StatusLabel>
    <StatusLabel type="success" style={{
      marginLeft: 'var(--spacing-xs)'
    }} mdxType="StatusLabel">
  Accessible
    </StatusLabel>
    <LeadParagraph mdxType="LeadParagraph">
    Tables are used to present tabulated data to the user in a consistent manner. HDS Tables offer multiple variants to allow clear presentation of vastly different data sets.
    </LeadParagraph>
    <Notification label="More table features are coming!" className="siteNotification" mdxType="Notification">
  HDS Tables are work in progress. The current feature set will serve most projects but later we are also looking into adding more features such as single row actions (edit, delete), expandable rows and supporting more HDS components inside tables.
    </Notification>
    <PageTabs pageContext={props.pageContext} mdxType="PageTabs">
  <PageTabs.TabList>
    <PageTabs.Tab href="/">Usage</PageTabs.Tab>
    <PageTabs.Tab href="/code">Code</PageTabs.Tab>
    <PageTabs.Tab href="/accessibility">Accessibility</PageTabs.Tab>
    <PageTabs.Tab href="/customisation">Customisation</PageTabs.Tab>
  </PageTabs.TabList>
  <PageTabs.TabPanel>{props.children}</PageTabs.TabPanel>
    </PageTabs>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      