import { Combobox, Select } from 'hds-react';
import PlaygroundPreview from "../../../../src/components/Playground";
import TabsLayout from "../../../../src/docs/components/dropdown/tabs.mdx";
import InternalLink from "../../../../src/components/InternalLink";
import * as React from 'react';
export default {
  Combobox,
  Select,
  PlaygroundPreview,
  TabsLayout,
  InternalLink,
  React
};