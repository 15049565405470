import { IconUser, Select } from 'hds-react';
import Playground from "../../../../src/components/Playground";
import TabsLayout from "../../../../src/docs/components/dropdown/tabs.mdx";
import * as React from 'react';
export default {
  IconUser,
  Select,
  Playground,
  TabsLayout,
  React
};