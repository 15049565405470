import ExternalLink from "../../../../src/components/ExternalLink";
import Playground from "../../../../src/components/Playground";
import ContrastExample from "../../../../src/components/examples/ContrastExample";
import FocusExample from "../../../../src/components/examples/FocusExample";
import TabsLayout from "../../../../src/docs/foundation/design-tokens/colour/tabs.mdx";
import * as React from 'react';
export default {
  ExternalLink,
  Playground,
  ContrastExample,
  FocusExample,
  TabsLayout,
  React
};