import { Button, Card, Link } from 'hds-react';
import ExternalLink from "../../../../src/components/ExternalLink";
import Playground from "../../../../src/components/Playground";
import TabsLayout from "../../../../src/docs/services/mobile/tabs.mdx";
import * as React from 'react';
export default {
  Button,
  Card,
  Link,
  ExternalLink,
  Playground,
  TabsLayout,
  React
};