import { useReducer } from 'react';
import { Stepper, StepState, Button, IconArrowLeft, IconArrowRight, Link } from 'hds-react';
import PlaygroundPreview from "../../../../src/components/Playground";
import TabsLayout from "../../../../src/docs/components/stepper/tabs.mdx";
import * as React from 'react';
export default {
  useReducer,
  Stepper,
  StepState,
  Button,
  IconArrowLeft,
  IconArrowRight,
  Link,
  PlaygroundPreview,
  TabsLayout,
  React
};