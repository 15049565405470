import { Button, IconAngleRight, IconShare, IconTrash, IconSaveDisketteFill, Notification } from 'hds-react';
import PlaygroundPreview from "../../../../src/components/Playground";
import TabsLayout from "../../../../src/docs/components/buttons/tabs.mdx";
import * as React from 'react';
export default {
  Button,
  IconAngleRight,
  IconShare,
  IconTrash,
  IconSaveDisketteFill,
  Notification,
  PlaygroundPreview,
  TabsLayout,
  React
};